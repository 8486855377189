export const getUpdatedNote = (notes: string, newNote: string) => {
  const date = new Date().toLocaleDateString();
  let notesUpdated = '';
  if (notes !== null && notes !== '') notesUpdated += notes;
  if (newNote !== null && newNote !== '') {
    if (notesUpdated !== '') notesUpdated += '\n';
    notesUpdated += `${date}: ${newNote}`;
  }
  if (notesUpdated === '') return null;
  return notesUpdated;
};

export const reverseNotes = (notes: string): string => {
  const notesArray = notes.split('\n');
  return notesArray.reverse().join('\n');
};

export const getNotesLines = (notes: string): number => {
  const notesArray = notes.split('\n');
  return notesArray.length;
};
