import { useEffect, useState } from 'react';
import Select from 'react-select';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import { handleRequestError } from '../../helpers/requests';
import { Developer } from '../../types';

const DeveloperSelect = ({ onChange }: { onChange: (e: any) => void }) => {
  const [developers, setDevelopers] = useState<Developer[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchDevelopers = async () => {
    setLoading(true);
    const response = await fetch(apiPrefix + '/developer', {
      headers: await getAuthHeader(),
    });
    if (response.ok) {
      const data = await response.json();
      const developersUnsorted: Array<Developer> = data.developers;
      const developersSorted = developersUnsorted.sort((a, b) =>
        a.developername.trim().localeCompare(b.developername.trim())
      );
      setDevelopers(developersSorted);
    } else handleRequestError(response);
    setLoading(false);
  };

  const handleChange = (e: any) => {
    if (e == null) {
      onChange({ value: null });
      return;
    }
    const developersFiltered = developers.filter(
      (d) => d.developerid === e.value
    );
    if (developersFiltered.length > 0) {
      const selectedNew = developersFiltered[0];
      onChange({ value: selectedNew });
    }
  };

  useEffect(() => {
    fetchDevelopers();
  }, []);

  return (
    <Select
      placeholder={loading ? 'Loading developers' : 'Select Developer'}
      isLoading={loading}
      onChange={handleChange}
      options={developers.map((d) => ({
        value: d.developerid,
        label: d.developername,
      }))}
    />
  );
};

export default DeveloperSelect;
