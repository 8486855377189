import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import JSZip from 'jszip';

const zip = new JSZip();
const S3_APPCSV_BUCKET = process.env.REACT_APP_S3_APPCSV_BUCKET || '';
const S3_APPCURATEDCSV_BUCKET =
  process.env.REACT_APP_S3_APPCURATEDCSV_BUCKET || '';
const S3_REGION = process.env.REACT_APP_S3_REGION || '';
AWS.config.region = S3_REGION;

export const listAllKeysDates = async (prefix: string) => {
  const allKeys: string[] = [];
  const datesModified = new Set<string>();

  const creds = await Auth.currentCredentials();
  AWS.config.credentials = creds;
  const s3 = new AWS.S3();
  const params = {
    Bucket: S3_APPCSV_BUCKET,
    Prefix: prefix,
  };

  const data = s3.listObjectsV2(params);
  await new Promise((resolve, reject) => {
    data.eachPage((err, data) => {
      if (err) {
        console.error(err);
        reject(err);
        return false;
      } else {
        if (data == null) {
          resolve(null);
          return false;
        }
        const contents = data.Contents;
        contents?.forEach((file) => {
          if (file.Key && file.Size !== 0) {
            allKeys.push(file.Key);
            if (file.LastModified)
              datesModified.add(file.LastModified.toISOString().split('T')[0]);
          }
        });
      }
      return true;
    });
  });
  return {
    keys: allKeys,
    dates: Array.from(datesModified),
  };
};

export const downloadFile = async (key: string, filename: string | null = null) => {
  const creds = await Auth.currentCredentials();
  AWS.config.credentials = creds;
  const s3 = new AWS.S3();
  const params = {
    Bucket: S3_APPCSV_BUCKET,
    Key: key,
  };
  const data = await new Promise<AWS.S3.GetObjectOutput>(
    (resolve, reject) => {
      s3.getObject(params, (err, data) => {
        if (err) {
          console.error(err);
          reject(err);
        } else resolve(data);
      });
    }
  );
  const file = new Blob([data.Body as BlobPart], { type: data.ContentType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.target = '_blank';
  link.download = filename || key;
  link.click();
};

export const downloadFiles = async (keys: string[], zipname: string) => {
  const creds = await Auth.currentCredentials();
  AWS.config.credentials = creds;
  const s3 = new AWS.S3();
  const blobs = keys.map(async (key) => {
    const params = {
      Bucket: S3_APPCSV_BUCKET,
      Key: key,
    };
    const data = await new Promise<AWS.S3.GetObjectOutput>(
      (resolve, reject) => {
        s3.getObject(params, (err, data) => {
          if (err) {
            console.error(err);
            reject(err);
          } else resolve(data);
        });
      }
    );
    const blob = new Blob([data.Body as BlobPart], { type: data.ContentType });
    return { key, blob };
  });

  const files = await Promise.all(blobs);
  files.forEach(({ key, blob }) => zip.file(key, blob));

  const blob = await zip.generateAsync({ type: 'blob' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.target = '_blank';
  link.download = zipname;
  link.click();
};

export const uploadFiles = async (files: File[], prefix = '') => {
  const creds = await Auth.currentCredentials();
  AWS.config.credentials = creds;
  const s3 = new AWS.S3();
  const promises = files.map((file) => {
    const params = {
      Bucket: S3_APPCURATEDCSV_BUCKET,
      Key: prefix + file.name,
      Body: file,
    };
    return new Promise((resolve, reject) => {
      s3.upload(params, (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
        if (err) {
          console.error(err);
          reject(err);
        } else resolve(data);
      });
    });
  });
  return await Promise.all(promises);
};



export const downloadNonS3File = (url: string, filename: string) => Object.assign(document.createElement('a'), { href: url, download: filename }).click();
