import { Authenticator } from '@aws-amplify/ui-react';
import { Route, Routes } from 'react-router-dom';
import BoHomes from './components/bohomes';
import BoTrack from './components/botrack';
import Navbar from './components/common/Navbar';
import Home from './components/Home';
import SiteVisits from './components/sitevisits';
import WebAdmins from './components/webadmins';

const components = {
  Header() {
    return (
      <div className="logo-container">
        <img alt="BoCreate logo" src="/logo-270x270.png" />
        <div className="logo-name">BoCreate Admin Console</div>
      </div>
    );
  },
};

const Main = () => {
  return (
    <Authenticator components={components}>
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/site_visits" element={<SiteVisits />} />
          <Route path="/bohomes" element={<BoHomes />} />
          <Route path="/botrack" element={<BoTrack />} />
          <Route path="/webadmins" element={<WebAdmins />} />
        </Routes>
      </>
    </Authenticator>
  );
};

export default Main;
