import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Application from './ApplicationForm';
import Catalogue from './Catalogue';
import Developer from './Developer';
import Development from './Development';
import HouseTypes from './HouseTypes';
import UploadCsv from './UploadCSV';
import Applicants from './Applicants';

const BoTrack = () => (
  <Container className="my-4">
    <Tabs variant="tabs" defaultActiveKey="upload_csv">
      {/* <Tab eventKey="application" title="Application">
        <Application />
      </Tab> */}
      <Tab eventKey="upload_csv" title="Upload Applications">
        <UploadCsv />
      </Tab>
      <Tab eventKey="applicants" title="Applicants">
        <Applicants />
      </Tab>
      <Tab eventKey="catalogue" title="Catalogue">
        <Catalogue />
      </Tab>
      <Tab eventKey="development" title="Development">
        <Development />
      </Tab>
      <Tab eventKey="developer" title="Developer">
        <Developer />
      </Tab>
      <Tab eventKey="housetypes" title="House Types">
        <HouseTypes />
      </Tab>
    </Tabs>
  </Container>
);

export default BoTrack;
