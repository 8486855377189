import { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import { handleRequestError } from '../../helpers/requests';
import { User } from '../../types';
import Toasts from '../common/Toasts';
import UserRow, { UserRowHead } from './UserRow';

const WebAdmins = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = async () => {
    setLoading(true);
    const requestPath = `${apiPrefix}/webadmin/`;
    const response = await fetch(requestPath, {
      headers: await getAuthHeader(),
    });
    if (response.ok) {
      const data = await response.json();
      const users: User[] = data.webadmins;
      setUsers(users);
    } else {
      setUsers([]);
      handleRequestError(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onUserRowChange = ({ user }: { user: User }) => {
    const newUsers = users.map((u) => {
      if (u.Username === user.Username) {
        return { ...u, Enabled: user.Enabled };
      }
      return u;
    });
    setUsers(newUsers);
    fetchUsers();
  };

  return (
    <Container className="my-4" style={{ position: 'relative' }}>
      <Toasts info={info} error={error} setInfo={setInfo} setError={setError} />
      <div className="h3 fw-light py-4">
        <span style={{ lineHeight: 1.5 }}>List of Web Admins</span>
        {loading && (
          <div className="spinner-grow mx-4 text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
      {users.length > 0 && (
        <Table striped hover>
          <thead>
            <UserRowHead />
          </thead>
          <tbody>
            {users.map((user) => (
              <UserRow
                key={user.Username}
                user={user}
                onChange={onUserRowChange}
              />
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default WebAdmins;
