import { useEffect, useState } from 'react';
import Select from 'react-select';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import { handleRequestError } from '../../helpers/requests';
import { Developer, Development } from '../../types';

const DevelopmentSelect = ({
  developer,
  onChange,
}: {
  developer: Developer | null;
  onChange: (e: any) => void;
}) => {
  const [developments, setDevelopments] = useState<Development[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchDevelopments = async () => {
    setLoading(true);
    const requestPath =
      developer != null
        ? `${apiPrefix}/developer/${developer?.developerid}/development`
        : `${apiPrefix}/development`;
    const response = await fetch(requestPath, {
      headers: await getAuthHeader(),
    });
    if (response.ok) {
      const data = await response.json();
      let developmentsUnsorted: Array<Development> = [];
      if (Object.hasOwnProperty.call(data, 'developments'))
        developmentsUnsorted = data.developments;
      else if (Object.hasOwnProperty.call(data, 'developer_developments'))
        developmentsUnsorted = data.developer_developments;
      else
        console.error('Expected developments or developer_developments', data);
      const developmentsSorted = developmentsUnsorted.sort((a, b) =>
        a.developmentname.trim().localeCompare(b.developmentname.trim())
      );
      setDevelopments(developmentsSorted);
    } else handleRequestError(response);
    setLoading(false);
  };

  const handleChange = (e: any, t: any) => {
    if (e == null || t?.action === 'clear') {
      onChange({ value: null });
      return;
    }
    const developmentsFiltered = developments.filter(
      (d) => d.developmentid === e.value
    );
    if (developmentsFiltered.length > 0) {
      const selected = developmentsFiltered[0];
      onChange({ value: selected });
    }
  };

  useEffect(() => {
    setDevelopments([]);
    fetchDevelopments();
  }, [developer]);

  return (
    <Select
      placeholder={loading ? 'Loading developments' : 'Select Development'}
      isLoading={loading}
      onChange={handleChange}
      isClearable={true}
      options={developments.map((d) => ({
        value: d.developmentid,
        label: d.developmentname,
      }))}
    />
  );
};

export default DevelopmentSelect;
