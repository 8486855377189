import { useAuthenticator } from '@aws-amplify/ui-react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';

const AppNavbar = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <div className="logo-container navbar">
            <img alt="BoCreate logo" src="/logo-270x270.png" />
            <div className="logo-name">BoCreate</div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/site_visits">
              Site Visits
            </Nav.Link>
            <Nav.Link as={NavLink} to="/botrack">
              BoTrack
            </Nav.Link>
            <Nav.Link as={NavLink} to="/bohomes">
              BoHomes
            </Nav.Link>
            <Nav.Link as={NavLink} to="/webadmins">
              Web Admins
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav.Link onClick={signOut}>Sign Out</Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
