import Container from 'react-bootstrap/Container';

const Home = () => (
  <Container className="my-4">
    <p className="display-2">Admin Panel</p>
    <p className="py-4">Welcome to BoCreate Admin Panel.</p>
    <p>You have been logged in, please select a tab to continue.</p>
  </Container>
);

export default Home;
