import { useAuthenticator } from '@aws-amplify/ui-react';
import { useRef, useState } from 'react';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import { handleRequestError } from '../../helpers/requests';
import { User } from '../../types';

const getEmail = (user: User) => {
  let email = 'Not Available';
  if (user.Attributes?.length > 0) {
    const emailAttr = user.Attributes.filter(
      (attr) => attr.Name === 'email'
    )[0];
    if (emailAttr) {
      email = emailAttr.Value;
    }
  }
  return email;
};

const UserRow = ({
  user,
  onChange,
}: {
  user: User;
  onChange: ({ user }: { user: User }) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { user: currentUser } = useAuthenticator((context) => [context.user]);
  const buttonRef = useRef(null);

  const updateUser = async () => {
    setLoading(true);
    const requestPath = `${apiPrefix}/webadmin/${user.Username}`;
    const response = await fetch(requestPath, {
      method: 'PATCH',
      headers: await getAuthHeader(),
      body: JSON.stringify({
        enable: !user.Enabled,
      }),
    });
    if (response.ok) {
      // const data = await response.json();
      setInfo('User updated successfully');
      setTimeout(() => setInfo(null), 1000);
      onChange({ user: { ...user, Enabled: !user.Enabled } });
    } else {
      setError('Failed to update user');
      handleRequestError(response);
      setTimeout(() => setError(null), 2000);
    }
    setLoading(false);
  };

  const buttonVariant = user.Enabled ? 'danger' : 'success';
  const buttonText = user.Enabled ? 'Disable' : 'Enable';
  const buttonDisabledText = `${buttonText.slice(0, -1)}ing...`;

  return (
    <tr>
      <td>{getEmail(user)}</td>
      <td>{user.Username}</td>
      <td>
        {currentUser?.username !== user.Username && (
          <>
            <Button
              ref={buttonRef}
              variant={buttonVariant}
              onClick={updateUser}
              disabled={loading}
              size="sm"
            >
              {loading ? buttonDisabledText : buttonText}
            </Button>
            <Overlay
              target={buttonRef.current}
              show={info != null}
              placement="right"
            >
              {(props) => <Tooltip {...props}>{info}</Tooltip>}
            </Overlay>
            <Overlay
              target={buttonRef.current}
              show={error != null}
              placement="right"
            >
              {(props) => <Tooltip {...props}>{error}</Tooltip>}
            </Overlay>
          </>
        )}
      </td>
    </tr>
  );
};

export const UserRowHead = () => {
  return (
    <tr>
      <th>Email</th>
      <th>Username</th>
      <th>Enable / Disable</th>
    </tr>
  );
};

export default UserRow;
