import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import {
  getNotesLines,
  getUpdatedNote,
  reverseNotes,
} from '../../helpers/notes';
import { handleRequestError } from '../../helpers/requests';
import { Developer, Development, DevelopmentSiteVists } from '../../types';
import DeveloperSelect from '../common/DeveloperSelect';
import DevelopmentSelect from '../common/DevelopmentSelect';
import ProgressSteps, { ProgressStep } from '../common/ProgressSteps';
import Toasts from '../common/Toasts';

const progressOptions = {
  construction: ['Not Started', 'GW', '25%', '50%', '75%', '90%', 'Complete'],
  marketing: ['Not Started', '25%', '50%', '75%', 'Complete'],
};

const SiteVisits = () => {
  const [developer, setDeveloper] = useState<Developer | null>(null);
  const [development, setDevelopment] = useState<DevelopmentSiteVists | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [constructionProgress, setConstructionProgress] = useState<
    string | null
  >(null);
  const [marketingProgress, setMarketingProgress] = useState<string | null>(
    null
  );
  const [constructionNotes, setConstructionNotes] = useState<string>('');
  const [constructionNewNote, setConstructionNewNote] = useState<string>('');
  const [marketingNotes, setMarketingNotes] = useState<string>('');
  const [marketingNewNote, setMarketingNewNote] = useState<string>('');
  const [pv, setPv] = useState(false);
  const [mmc, setMmc] = useState(false);
  const [chp, setChp] = useState(false);
  const [ashp, setAshp] = useState(false);
  const [gshp, setGshp] = useState(false);

  const developerChange = (e: any) => {
    setDeveloper(e.value);
    setDevelopment(null);
  };

  const fetchDevelopmentSiteVisits = async (development: Development) => {
    setLoading(true);
    const requestPath = `${apiPrefix}/development/${development?.developmentid}/siteVisits`;
    const response = await fetch(requestPath, {
      headers: await getAuthHeader(),
    });
    if (response.ok) {
      const data = await response.json();
      const dsv: DevelopmentSiteVists = data.development;
      setDevelopment(dsv);
      setConstructionProgress(dsv.constructionprogress);
      setMarketingProgress(dsv.marketingprogress);
      setConstructionNotes(dsv.constructionprogressnotes);
      setMarketingNotes(dsv.marketingprogressnotes);
      setPv(dsv.carbonmeasurepv);
      setMmc(dsv.carbonmeasuremmc);
      setChp(dsv.carbonmeasurechp);
      setAshp(dsv.carbonmeasureashp);
      setGshp(dsv.carbonmeasuregshp);
    } else {
      setDevelopment(null);
      handleRequestError(response);
    }
    setLoading(false);
  };

  const developmentChange = async (e: any) => {
    const development: Development = e.value;
    if (development == null) setDevelopment(null);
    else fetchDevelopmentSiteVisits(development);
  };

  const constructionProgressChange = (e: any) => {
    const i: number = e.index;
    if (i >= 0 && i < progressOptions.construction.length)
      setConstructionProgress(progressOptions.construction[i]);
  };
  const marketingProgressChange = (e: any) => {
    const i: number = e.index;
    if (i >= 0 && i < progressOptions.marketing.length)
      setMarketingProgress(progressOptions.marketing[i]);
  };

  const constructionNewNoteChange = (e: any) =>
    setConstructionNewNote(e.target.value);
  const marketingNewNoteChange = (e: any) =>
    setMarketingNewNote(e.target.value);

  const pvChange = (e: any) => setPv(e.target.checked);
  const mmcChange = (e: any) => setMmc(e.target.checked);
  const chpChange = (e: any) => setChp(e.target.checked);
  const ashpChange = (e: any) => setAshp(e.target.checked);
  const gshpChange = (e: any) => setGshp(e.target.checked);

  const handleUpdate = async () => {
    const newDevelopment = {
      ...development,
      constructionprogress: constructionProgress,
      marketingprogress: marketingProgress,
      constructionprogressnotes: getUpdatedNote(
        constructionNotes,
        constructionNewNote
      ),
      marketingprogressnotes: getUpdatedNote(marketingNotes, marketingNewNote),
      carbonmeasurepv: pv,
      carbonmeasuremmc: mmc,
      carbonmeasurechp: chp,
      carbonmeasureashp: ashp,
      carbonmeasuregshp: gshp,
    };
    await updateDevelopment(newDevelopment);
  };

  const updateDevelopment = async (newDevelopment: any) => {
    setUpdating(true);
    const response = await fetch(
      `${apiPrefix}/development/${development?.developmentid}/siteVisits`,
      {
        method: 'PATCH',
        headers: await getAuthHeader({ isJson: true }),
        body: JSON.stringify(newDevelopment),
      }
    );
    if (response.ok && development != null) {
      // const data = await response.json();
      setInfo('Development updated successfully');
      fetchDevelopmentSiteVisits(development);
      setTimeout(() => setInfo(null), 3000);
      setConstructionNewNote('');
      setMarketingNewNote('');
    } else {
      handleRequestError(response);
      setError('Error updating development');
      setTimeout(() => setError(null), 5000);
    }
    setUpdating(false);
  };

  return (
    <Container className="my-4" style={{ position: 'relative' }}>
      <Toasts info={info} error={error} setInfo={setInfo} setError={setError} />
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm>
            Developer
          </Form.Label>
          <Col sm={10}>
            <DeveloperSelect onChange={developerChange} />
          </Col>
        </Form.Group>
        {developer != null && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm>
              Development
            </Form.Label>
            <Col sm={10}>
              <DevelopmentSelect
                key={developer.developerid}
                developer={developer}
                onChange={developmentChange}
              />
            </Col>
          </Form.Group>
        )}
        {loading && <div>Loading...</div>}
        {!loading && development != null && (
          <>
            <fieldset>
              <Form.Group as={Row} className="mb-3 mt-4">
                <Form.Label as="legend" column sm={2}>
                  Construction Progress
                </Form.Label>
                <Col sm={10}>
                  <ProgressSteps onChange={constructionProgressChange}>
                    {progressOptions.construction.map((option, index) => (
                      <ProgressStep
                        key={index}
                        id={index}
                        active={option === constructionProgress}
                      >
                        {option}
                      </ProgressStep>
                    ))}
                  </ProgressSteps>
                </Col>
              </Form.Group>
              {constructionNotes != null && (
                <Form.Group as={Row} className="mb-3 mt-4">
                  <Form.Label column sm={2}>
                    Notes
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      readOnly
                      value={reverseNotes(constructionNotes)}
                    />
                    <Form.Text className="text-muted">
                      Previously added {getNotesLines(constructionNotes)} notes,
                      most recent on top, readonly
                    </Form.Text>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Add Note
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={constructionNewNote || ''}
                    onChange={constructionNewNoteChange}
                  />
                  <Form.Text className="text-muted">
                    Date added will be added to the note automatically
                  </Form.Text>
                </Col>
              </Form.Group>
            </fieldset>
            <fieldset>
              <Form.Group as={Row} className="mb-3 mt-4">
                <Form.Label as="legend" column sm={2}>
                  Marketing Progress
                </Form.Label>
                <Col sm={10}>
                  <ProgressSteps onChange={marketingProgressChange}>
                    {progressOptions.marketing.map((option, index) => (
                      <ProgressStep
                        key={index}
                        id={index}
                        active={option === marketingProgress}
                      >
                        {option}
                      </ProgressStep>
                    ))}
                  </ProgressSteps>
                </Col>
              </Form.Group>
              {marketingNotes != null && (
                <Form.Group as={Row} className="mb-3 mt-4">
                  <Form.Label column sm={2}>
                    Notes
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      readOnly
                      value={reverseNotes(marketingNotes)}
                    />
                    <Form.Text className="text-muted">
                      Previously added {getNotesLines(marketingNotes)} notes,
                      most recent on top, readonly
                    </Form.Text>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Add Note
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={marketingNewNote || ''}
                    onChange={marketingNewNoteChange}
                  />
                  <Form.Text className="text-muted">
                    Date added will be added to the note automatically
                  </Form.Text>
                </Col>
              </Form.Group>
            </fieldset>
            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formBasicCheckbox"
              >
                <Form.Label as="legend" column sm={2}>
                  Carbon Measures
                </Form.Label>
                <Col sm={10}>
                  <Form.Check
                    type="switch"
                    onChange={pvChange}
                    checked={pv}
                    label="PV"
                  />
                  <Form.Check
                    type="switch"
                    onChange={mmcChange}
                    checked={mmc}
                    label="MMC"
                  />
                  <Form.Check
                    type="switch"
                    onChange={chpChange}
                    checked={chp}
                    label="CHP"
                  />
                  <Form.Check
                    type="switch"
                    onChange={ashpChange}
                    checked={ashp}
                    label="ASHP"
                  />
                  <Form.Check
                    type="switch"
                    onChange={gshpChange}
                    checked={gshp}
                    label="GSHP"
                  />
                </Col>
              </Form.Group>
            </fieldset>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 2 }}>
                <Button onClick={handleUpdate} disabled={updating}>
                  {updating ? 'Updating...' : 'Update'}
                </Button>
              </Col>
            </Form.Group>
          </>
        )}
      </Form>
    </Container>
  );
};

export default SiteVisits;
