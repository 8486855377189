import React, { useEffect, useState } from 'react';

export const ProgressStep = ({
  children,
}: {
  id: any;
  active: boolean;
  children: any;
}) => {
  return <>{children}</>;
};

const ProgressSteps = ({
  onChange,
  children,
}: {
  onChange?: (e: any) => void;
  children: any;
}) => {
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [steps, setSteps] = useState<any[]>([]);

  const handleChange = (index: number) => {
    setActiveStep(index);
    onChange && onChange({ index });
  };

  useEffect(() => {
    const data = React.Children.map(children, (e, i) => {
      if (!React.isValidElement(e) || e.type !== ProgressStep) return;
      const {
        props: { active, children, id },
      }: { props: any } = e;
      if (active) setActiveStep(i);
      return { id, children };
    });
    setSteps(data);
  }, [children]);

  return (
    <ul className="steps has-gaps has-content-centered">
      {steps.map(({ children, id }, index) => {
        const isActive = index === activeStep;
        const handleChangeIndex = () => handleChange(index);
        return (
          <li
            key={id}
            className={`steps-segment ${isActive ? 'is-active' : ''}`}
          >
            <span
              className="steps-marker"
              style={{ cursor: 'pointer' }}
              onClick={handleChangeIndex}
            >
              {isActive && (
                <span className="icon">
                  <i className="fa fa-check" />
                </span>
              )}
            </span>
            <div
              style={{ cursor: 'pointer' }}
              className="steps-content"
              onClick={handleChangeIndex}
            >
              {children}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ProgressSteps;
