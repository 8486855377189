import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DevelopmentDetails = () => {
  return (
    <Container className="my-4">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Development Name
        </Form.Label>
        <Col sm={10}>
          <Form.Control type="text" placeholder="Eden Grove" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10, offset: 2 }}>
          <Button type="submit" variant="outline-primary">
            Retrieve Details
          </Button>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Development Name
        </Form.Label>
        <Col sm={10}>
          <Form.Control type="text" placeholder="Enter New Details" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10, offset: 2 }}>
          <Button type="submit">Update</Button>
        </Col>
      </Form.Group>
    </Container>
  );
};

export default DevelopmentDetails;
