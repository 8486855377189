import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import { handleRequestError } from '../../helpers/requests';
import { Developer, DeveloperFinanceOptions } from '../../types';
import DeveloperSelect from '../common/DeveloperSelect';
import Toasts from '../common/Toasts';

const DeveloperForm = () => {
  const [dfo, setDfo] = useState<DeveloperFinanceOptions | null>(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [financeOptions, setFinanceOptions] = useState<string | null>(null);

  const developerChange = (e: any) => {
    const developer: Developer = e.value;
    fetchDeveloper(developer);
  };

  const fetchDeveloper = async (
    developer: Developer | DeveloperFinanceOptions
  ) => {
    setLoading(true);
    const requestPath = `${apiPrefix}/developer/${developer?.developerid}`;
    const response = await fetch(requestPath, {
      headers: await getAuthHeader(),
    });
    if (response.ok) {
      const data = await response.json();
      const dataDeveloper: DeveloperFinanceOptions = data.developer;
      if (dataDeveloper != null) {
        setDfo(dataDeveloper);
        setFinanceOptions(dataDeveloper.financeoptions);
      } else {
        setError('Developer not found');
        setTimeout(() => setError(null), 5000);
      }
    } else {
      setDfo(null);
      handleRequestError(response);
    }
    setLoading(false);
  };

  const financeOptionsChange = (e: any) => setFinanceOptions(e.target.value);

  const handleUpdate = async () => {
    if (dfo == null) return;
    const newDeveloper: DeveloperFinanceOptions = {
      developerid: dfo?.developerid,
      financeoptions: financeOptions === '' ? null : financeOptions,
    };
    updateDeveloper(newDeveloper);
  };

  const updateDeveloper = async (developer: DeveloperFinanceOptions) => {
    setUpdating(true);
    const requestPath = `${apiPrefix}/developer/${developer.developerid}/financeOptions`;
    const response = await fetch(requestPath, {
      method: 'PATCH',
      headers: await getAuthHeader({ isJson: true }),
      body: JSON.stringify(developer),
    });
    if (response.ok) {
      // const data = await response.json();
      setInfo('Developer updated successfully');
      fetchDeveloper(developer);
      setTimeout(() => setInfo(null), 3000);
    } else {
      handleRequestError(response);
      setError('Error updating development');
      setTimeout(() => setError(null), 5000);
    }
    setUpdating(false);
  };

  return (
    <Container className="my-4" style={{ position: 'relative' }}>
      <Toasts error={error} info={info} setError={setError} setInfo={setInfo} />
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Developer
        </Form.Label>
        <Col sm={10}>
          <DeveloperSelect onChange={developerChange} />
        </Col>
      </Form.Group>
      {loading && <div>Loading...</div>}
      {!loading && dfo != null && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm>
              Finance Options
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="textarea"
                value={financeOptions || ''}
                onChange={financeOptionsChange}
                rows={3}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <Button onClick={handleUpdate} disabled={updating}>
                {updating ? 'Updating...' : 'Update'}
              </Button>
            </Col>
          </Form.Group>
        </>
      )}
    </Container>
  );
};

export default DeveloperForm;
