import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import {
  getNotesLines,
  getUpdatedNote,
  reverseNotes,
} from '../../helpers/notes';
import { handleRequestError } from '../../helpers/requests';
import {
  Developer,
  Development,
  DevelopmentMarketTotalHomes,
} from '../../types';
import DeveloperSelect from '../common/DeveloperSelect';
import DevelopmentSelect from '../common/DevelopmentSelect';
import Toasts from '../common/Toasts';

const DevelopmentForm = () => {
  const [developer, setDeveloper] = useState<Developer | null>(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [dmth, setDmth] = useState<DevelopmentMarketTotalHomes | null>(null);

  const [mth, setMth] = useState<number>(-1);
  const [sn, setSn] = useState<number>(-1);
  const [dNotes, setDNotes] = useState<string>('');
  const [dNewNote, setDNewNote] = useState<string>('');

  const developerChange = (e: any) => {
    setDeveloper(e.value);
    setDmth(null);
  };
  const developmentChange = (e: any) => {
    const development: Development = e.value;
    fetchDmth(development);
  };

  const fetchDmth = async (
    development: Development | DevelopmentMarketTotalHomes
  ) => {
    setLoading(true);
    const requestPath = `${apiPrefix}/development/${development?.developmentid}`;
    const response = await fetch(requestPath, {
      headers: await getAuthHeader(),
    });
    if (response.ok) {
      const data = await response.json();
      const dataDmth: DevelopmentMarketTotalHomes = data.development;
      if (dataDmth != null) {
        setDmth(dataDmth);
        setMth(dataDmth.markettotalhomes);
        setSn(dataDmth.startingnumber);
        setDNotes(dataDmth.developmentnotes || '');
      } else {
        setError('Development not found');
        setTimeout(() => setError(null), 5000);
      }
    } else {
      setDmth(null);
      handleRequestError(response);
    }
    setLoading(false);
  };

  const dNewNoteChange = (e: any) => setDNewNote(e.target.value);
  const mthChange = (e: any) => setMth(parseInt(e.target.value));
  const snChange = (e: any) => setSn(parseInt(e.target.value));

  const handleUpdate = async () => {
    if (dmth == null) return;
    const newDmth: DevelopmentMarketTotalHomes = {
      developmentid: dmth?.developmentid,
      markettotalhomes: mth,
      startingnumber: sn,
      developmentnotes: getUpdatedNote(dNotes, dNewNote),
    };
    updateDmth(newDmth);
  };

  const updateDmth = async (newDmth: DevelopmentMarketTotalHomes) => {
    setUpdating(true);
    const requestPath = `${apiPrefix}/development/${newDmth.developmentid}/marketTotalHomes`;
    const response = await fetch(requestPath, {
      method: 'PATCH',
      headers: await getAuthHeader({ isJson: true }),
      body: JSON.stringify(newDmth),
    });
    if (response.ok) {
      setInfo('Development updated');
      setTimeout(() => setInfo(null), 5000);
      fetchDmth(newDmth);
      setDNewNote('');
    } else {
      handleRequestError(response);
      setError('Error updating development');
      setTimeout(() => setError(null), 5000);
    }
    setUpdating(false);
  };

  return (
    <Container className="my-4" style={{ position: 'relative' }}>
      <Toasts error={error} info={info} setError={setError} setInfo={setInfo} />
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Developer
        </Form.Label>
        <Col sm={10}>
          <DeveloperSelect onChange={developerChange} />
        </Col>
      </Form.Group>
      {developer != null && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm>
            Development
          </Form.Label>
          <Col sm={10}>
            <DevelopmentSelect
              key={developer.developerid}
              developer={developer}
              onChange={developmentChange}
            />
          </Col>
        </Form.Group>
      )}
      {loading && <div>Loading...</div>}
      {!loading && dmth != null && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm>
              Market Total Homes
            </Form.Label>
            <Col sm={10}>
              <Form.Control type="number" onChange={mthChange} value={mth} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm>
              Starting Number
            </Form.Label>
            <Col sm={10}>
              <Form.Control type="number" onChange={snChange} value={sn} />
            </Col>
          </Form.Group>
          {dNotes && (
            <Form.Group as={Row} className="mb-3 mt-4">
              <Form.Label column sm={2}>
                Notes
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  readOnly
                  value={reverseNotes(dNotes)}
                />
                <Form.Text className="text-muted">
                  Previously added {getNotesLines(dNotes)} notes, most recent on
                  top, readonly
                </Form.Text>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm>
              Note
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="textarea"
                value={dNewNote}
                onChange={dNewNoteChange}
                rows={3}
              />
              <Form.Text className="text-muted">
                Date added will be added to the note automatically
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <Button onClick={handleUpdate} disabled={updating}>
                {updating ? 'Updating...' : 'Update'}
              </Button>
            </Col>
          </Form.Group>
        </>
      )}
    </Container>
  );
};

export default DevelopmentForm;
