import { parse } from '@loaders.gl/core';
import { CSVLoader } from '@loaders.gl/csv';
import { useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
// import { getLowercaseKeys } from '../../helpers/object';
import { downloadNonS3File } from '../../helpers/s3';
import Toasts from '../common/Toasts';

const Applicants = () => {
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [filesSelected, setFilesSelected] = useState<File[]>([]);
  const uploadFilesRef = useRef<HTMLInputElement>(null);

  const uploadOnChangeEvent = (e: any) =>
    setFilesSelected(Array.from(e.target.files));

  const uploadFiles = async (filesSelected: File[]) => {
    const dataArr = await Promise.all(
      filesSelected.map(async (file: any) => {
        const csvData = await parse(file, CSVLoader);
        return csvData.map((e: any) => e.names);
      })
    );
    const requestPath = apiPrefix + '/applicant/company';
    const response = await fetch(requestPath, {
      method: 'POST',
      headers: await getAuthHeader({ isJson: true }),
      body: JSON.stringify({ names: dataArr?.[0] }),
    });
    if (response.ok) {
      setInfo('CSV uploaded successfully');
      setFilesSelected([]);
      if (uploadFilesRef?.current != null) uploadFilesRef.current.value = '';
      setTimeout(() => setInfo(null), 5000);
    } else {
      setError('Error uploading CSVs');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    await uploadFiles(filesSelected);
    setUploading(false);
  };

  return (
    <Container className="my-4" style={{ position: 'relative' }}>
      <Toasts info={info} error={error} setInfo={setInfo} setError={setError} />
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Retrieve Format
        </Form.Label>
        <Col sm={10}>
          <Button
            type="submit"
            variant="outline-primary"
            onClick={() => downloadNonS3File('https://bocreate-csv-upload-templates.s3.eu-west-1.amazonaws.com/applicant.csv', 'applicant.csv')}
          >
            Retrieve Applicants Format
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Upload CSV
        </Form.Label>
        <Col sm={4}>
          <Form.Control
            ref={uploadFilesRef}
            type="file"
            accept=".csv"
            onChange={uploadOnChangeEvent}
          />
        </Col>
        <Col sm={6}>
          <Button
            onClick={handleUpload}
            disabled={uploading || filesSelected.length === 0}
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Col>
      </Form.Group>
    </Container>
  );
};

export default Applicants;
