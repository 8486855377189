import { Auth } from 'aws-amplify';

export const getAuthHeader = async (
  {
    isJson = false,
  }: {
    isJson?: boolean;
  } = { isJson: false }
): Promise<HeadersInit> => {
  const authData = await Auth.currentSession();
  const idToken = authData.getIdToken().getJwtToken();
  const headers: HeadersInit = {};
  if (idToken) headers['Authorization'] = `Bearer ${idToken}`;
  if (isJson) headers['Content-Type'] = 'application/json';
  return headers;
};
