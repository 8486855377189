import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AskingPriceURL from './AskingPriceURL';
import DeveloperForm from './DeveloperForm';
import DevelopmentDetails from './DevelopmentDetails';
import DevelopmentForm from './DevelopmentForm';

const BoHomes = () => (
  <Container className="my-4">
    <Tabs variant="tabs" defaultActiveKey="asking_price_url">
      <Tab eventKey="asking_price_url" title="Asking Price URL">
        <AskingPriceURL />
      </Tab>
      <Tab eventKey="development" title="Development Update">
        <DevelopmentForm />
      </Tab>
      <Tab eventKey="developer" title="Developer Update">
        <DeveloperForm />
      </Tab>
      <Tab eventKey="details_editor" title="Details Editor">
        <DevelopmentDetails />
      </Tab>
    </Tabs>
  </Container>
);

export default BoHomes;
