import { parse } from '@loaders.gl/core';
import { CSVLoader } from '@loaders.gl/csv';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { apiPrefix } from '../../config';
import { getAuthHeader } from '../../helpers/auth';
import { getLowercaseKeys } from '../../helpers/object';
import { Developer } from '../../types';
import DeveloperSelect from '../common/DeveloperSelect';
import Toasts from '../common/Toasts';
import { downloadNonS3File } from '../../helpers/s3';

const AskingPriceURL = () => {
  const dateToday = new Date().toISOString().slice(0, 10);
  const [date, setDate] = useState<string>(dateToday);
  const [developer, setDeveloper] = useState<Developer | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [filesSelected, setFilesSelected] = useState<File[]>([]);
  const uploadFilesRef = useRef<HTMLInputElement>(null);

  const uploadOnChangeEvent = (e: any) =>
    setFilesSelected(Array.from(e.target.files));

  const uploadFiles = async (filesSelected: File[]) => {
    const dataArr = await Promise.all(
      filesSelected.map(async (file: any) => {
        const csvData = await parse(file, CSVLoader);
        return csvData.map((e: any) => getLowercaseKeys(e));
      })
    );
    const requestPath = apiPrefix + '/plots';
    const response = await fetch(requestPath, {
      method: 'POST',
      headers: await getAuthHeader({ isJson: true }),
      body: JSON.stringify({
        date: date,
        developerid: developer?.developerid,
        plots: dataArr?.[0],
      }),
    });
    if (response.ok) {
      setInfo('CSV uploaded successfully');
      setFilesSelected([]);
      if (uploadFilesRef?.current != null) uploadFilesRef.current.value = '';
      setTimeout(() => setInfo(null), 5000);
    } else {
      const data = await response.json();
      setError(null ?? data.error);
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleDragOver = (e: any) => {
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e: any) => {
    // const file = e.dataTransfer.files[0]; // Get the first dropped file
    // Perform any necessary processing with the dropped file
    // For example, you can access its properties or initiate an upload
  
    // Call your upload function passing the dropped file
    setFilesSelected(Array.from(e.target.files));
  };

  const developerChange = (e: any) => setDeveloper(e.value);
  const onChangeDate = (e: any) => setDate(e.target.value);
  const handleUpload = async () => {
    setUploading(true);
    await uploadFiles(filesSelected);
    setUploading(false);
  };

  return (
    <Container className="my-4" style={{ position: 'relative' }}>
      <Toasts info={info} error={error} setInfo={setInfo} setError={setError} />
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Developer
        </Form.Label>
        <Col sm={10}>
          <DeveloperSelect onChange={developerChange} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          CSV Date
        </Form.Label>
        <Col sm={10}>
          <Form.Control type="date" onChange={onChangeDate} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Retrieve Format
        </Form.Label>
        <Col sm={10}>
          <Button
            type="submit"
            variant="outline-primary"
            onClick={() => downloadNonS3File('https://bocreate-csv-upload-templates.s3.eu-west-1.amazonaws.com/askingprice.csv', 'askingprice.csv')}
          >
            Retrieve Asking Price Format
          </Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm>
          Upload CSV
        </Form.Label>
        <Col sm={4}>
          <Form.Control
            ref={uploadFilesRef}
            type="file"
            accept=".csv"
            onChange={uploadOnChangeEvent}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          />
        </Col>
        <Col sm={6}>
          <Button
            onClick={handleUpload}
            disabled={
              developer == null || uploading || filesSelected.length === 0
            }
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Col>
      </Form.Group>
    </Container>
  );
};

export default AskingPriceURL;
