import { Toast, ToastContainer } from 'react-bootstrap';

const Toasts = ({
  info,
  error,
  setInfo,
  setError,
}: {
  info: string | null;
  error: string | null;
  setInfo: (info: string | null) => void;
  setError: (error: string | null) => void;
}) => {
  return (
    <ToastContainer position="bottom-end">
      <Toast onClose={() => setInfo(null)} show={info != null} bg="success">
        <Toast.Header>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>{info}</Toast.Body>
      </Toast>
      <Toast onClose={() => setError(null)} show={error != null} bg="danger">
        <Toast.Header>
          <strong className="me-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>{error}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Toasts;
